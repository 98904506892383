import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Integration } from "./Integration";
import { IntegrationMsgProps } from "./IntegrationMsg";
import { SettingsEntry } from "./IntegrationSettings";

/**
 * A generic page which simply takes the auth code form the url params (using the 'code' key) and 
 * allows the user to copy it. Used for integrations that bring their own app and all they require is 
 * the user to provide the code after the redirect. GMail Single User is an example. 
 * 
 * The url will look in this form: /authcode=?code=XXX
 */

const GenericAuthCode: React.FC<RouteComponentProps> = props => {
    const params = new URLSearchParams(props.location.search);
    const code = params.get("code");
    const msg: IntegrationMsgProps = {}
    const settings_entries: SettingsEntry[] = []
    if (!code) {
        msg.msg_body = "Auth Code was not found in URL params under the 'code' key"
        msg.msg_title = "Error Obtaining Auth Code"
        msg.msg_is_error = true
    } else {
        settings_entries[0] = {name: "Code", value: code}            
    }
    return (
        <Integration
            name="Auth Code"
            show_auth_button={false}            
            settings_props={{ entries: settings_entries }}
            msg_props={msg}
        />
    )
}

export default GenericAuthCode;