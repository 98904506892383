import React, {useEffect, useState} from "react";
import {GenericIntegrationProps, Integration} from "./Integration";
import {IntegrationMsgProps} from "./IntegrationMsg";
import {SettingsEntry} from "./IntegrationSettings";
import {CDL_tokens} from "./CDL_token";

/**
 * CDL Integration component
 */

//key to use to avoid resubmission on refresh
const FETCHED_TOKENS = "cdl.tokens";
const MISSING_ARGUMENTS = "missing.arguments";

interface CDLIntegrationStateProps extends IntegrationMsgProps {
    registration_id?: string;
    refresh_token?: string;
    encryption_key?: string;
    region?: string;
    instance_id?: string
}

const CDLIntegrationShowDetails: React.FC<GenericIntegrationProps> = props => {
    sessionStorage.setItem(FETCHED_TOKENS, "");
    const params = new URLSearchParams(props.location.search);
    let state = params.get("state") as string;
    let code = params.get("code");
    console.log("code: %s", code);
    console.log("state: %s", state);

    const [region, instance_id, customer_name, license_id] = parse_state(state);
    const is_error = code === "null" || state === "null" || !!params.get("error");

    const [state_props, set_state_props] = useState<CDLIntegrationStateProps>({
        msg_body: is_error ? (!!params.get("error") ? params.get("error") : "missing state or code parameters") : null,
        msg_title: is_error ? params.get("error") : null,
        msg_is_error: is_error
    });
    const redirect_callback = async () => {
        try {
            let resp;
            if (!sessionStorage.getItem(FETCHED_TOKENS) && !!code && !!region && !!instance_id) {
                set_state_props({
                    msg_is_error: false,
                    msg_body: `Obtaining tokens ...`,
                    msg_loader: true
                });
                resp = await CDL_tokens(code, region, instance_id, customer_name, license_id);
                sessionStorage.setItem(FETCHED_TOKENS, resp);
            } else {
                resp = sessionStorage.getItem(FETCHED_TOKENS) || "";
            }
            const token_response = JSON.parse(resp);
            let missing_arguments = get_missing_arguments(
                token_response.registration_id,
                token_response.refresh_token,
                token_response.encryption_key,
                region);
            let has_missing_arguments = missing_arguments.length > 0;
            sessionStorage.setItem(MISSING_ARGUMENTS, has_missing_arguments ? missing_arguments.toString() : "");
            set_state_props({
                registration_id: token_response.registration_id,
                refresh_token: token_response.refresh_token,
                encryption_key: token_response.encryption_key,
                region: region,
                instance_id: instance_id,
                msg_body: has_missing_arguments ? "The following arguments are missing: " + missing_arguments : null,
                msg_title: has_missing_arguments ? "Missing arguments" : null,
                msg_is_error: has_missing_arguments,
            });


        } catch (error) {
            console.log(error.message);
            set_state_props({
                msg_is_error: true,
                msg_title: `${error}`
            });
        }
    };

    const obtain_tokens = async () => {
        try {
            if (code) {
                await redirect_callback();
            } else {
                console.log(
                    "No auth code yet so skipping trying to get some CDL tokens"
                );
            }
        } catch (error) {
            console.log("In catch clause");
            console.log(error);
            set_state_props({
                msg_title: "Error",
                msg_body: error.message,
                msg_is_error: true
            });
            return;
        }
    };

    useEffect(() => {
        if (
            !state_props.msg_body &&
            !state_props.msg_title &&
            !state_props.registration_id
        ) {
            //code provided let's get a user auth token
            console.log("call `obtain_tokens` function");
            obtain_tokens();
        }
    });

    function get_missing_arguments(token: string, refresh_token: string, key: string, region: string) {
        let missing_arguments = [];
        if (!token) {
            missing_arguments.push("registration_id");
        }
        if (!refresh_token) {
            missing_arguments.push("refresh_token");
        }
        if (!key) {
            missing_arguments.push("encryption_key");
        }
        if (!region) {
            missing_arguments.push("region");
        }
        return missing_arguments
    }

    const settings_entries: SettingsEntry[] = [];
    if (sessionStorage.getItem(MISSING_ARGUMENTS) === "") {
        settings_entries.push({
            name: "Authentication Token",
            value: state_props.refresh_token!
        });
        settings_entries.push({
            name: "Registration ID",
            value: state_props.registration_id!
        });
        settings_entries.push({
            name: "Encryption Key",
            value: state_props.encryption_key!
        });
    }

    function parse_state(state: string): string[] {
        try {
            const split_state = state.split(":", 4);
            const region = split_state[0];
            const instance_id = split_state[1];
            const customer_name = split_state[2];
            const license_id = split_state[3];
            console.log("region: %s", region);
            console.log("instance_id: %s", instance_id);
            console.log("customer_name: %s", customer_name);
            console.log("license_id: %s", license_id);
            return [region, instance_id, customer_name, license_id]
        } catch (e) {
            return ["", "", "", ""]
        }
    }

    return (
        <Integration
            name="Strata Logging Service"
            show_auth_button={false}
            msg_props={state_props}
            settings_props={{entries: settings_entries}}
        />
    );
};

export default CDLIntegrationShowDetails;


