import axios from 'axios';

const REGISTER_URL = process.env.REACT_APP_REGISTER_URL || "/register";

const REGISTER_NON_ADMIN_URL = process.env.REACT_APP_REGISTER_NON_ADMIN_URL || "/register-non-admin";
/**
 * Register consent with the oauth2 proxy. Will cache the result in session storage to avoid re=registering
 * when doing a browser refresh.
 * @param user_token 
 * @param tenant 
 * @param app_id
 * @param is_non_admin
 */
export const register_ms_consent = async (user_token: string, tenant: string, app_id: string, is_non_admin?: boolean) => {
    const cache_name = "consent_cache." + app_id;
    const cache = sessionStorage.getItem(cache_name);
    if(cache) {
        return JSON.parse(cache);
    }
    // will be performed server side
    const register_url = is_non_admin? REGISTER_NON_ADMIN_URL : REGISTER_URL;
    const data = {
        tenant,
        user_token,
        app_id
    }
    const resp = await axios.post(register_url, data,);
    const res = resp.data;
    console.log('register response: ', res);
    sessionStorage.setItem(cache_name, JSON.stringify(res));
    return res;
}
