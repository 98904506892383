import React from 'react';
import logo from './logo.svg';
import { Container, Grid, Image, Segment } from 'semantic-ui-react'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import MSIntegration from './MSIntegration';
import CDLIntegrationCustomerValidation from './CDLEnrty'
import CDLIntegrationShowDetails from "./CDLAuthDetails";
import GenericAuthCode from "./GenericAuthCode";

const App: React.FC = () => {
  return (
    <Router>
      <Grid verticalAlign="middle" stretched centered style={{ height: '100vh' }}>
        <Grid.Column>
          <Image src={logo} centered size="large" />
          <Route path="/ms-graph-device-management" exact render={(p) => <MSIntegration name="Microsoft Graph Device Management"
            app_client_id={process.env.REACT_APP_MS_GRAPH_DEVICE_MANAGEMENT_ID!} {...p} />} />
          <Route path="/ms-graph-security" exact render={(p) => <MSIntegration name="Microsoft Graph Security"
            app_client_id={process.env.REACT_APP_MS_GRAPH_SEC_ID!} {...p} />} />
          <Route path="/ms-graph-user" exact render={(p) => <MSIntegration name="Microsoft Graph User"
            app_client_id={process.env.REACT_APP_MS_GRAPH_USER_ID!} {...p} />} />
          <Route path="/ms-graph-groups" exact render={(p) => <MSIntegration name="Microsoft Graph Groups"
            app_client_id={process.env.REACT_APP_MS_GRAPH_GROUPS_ID!} {...p} />} />
          <Route path="/ms-graph-calendar" exact render={(p) => <MSIntegration name="Microsoft Graph Calendar"
            app_client_id={process.env.REACT_APP_MS_GRAPH_CALENDAR_ID!} {...p} />} />
          <Route path="/ms-graph-mail" exact render={(p) => <MSIntegration name="Microsoft Graph Mail"
            app_client_id={process.env.REACT_APP_MS_GRAPH_MAIL_ID!} {...p} />} />
          <Route path="/ms-graph-mail-listener" exact render={(p) => <MSIntegration name="Microsoft Graph Mail Listener And Sender" is_non_admin={true}
            app_client_id={process.env.REACT_APP_MS_GRAPH_MAIL_LISTENER_ID!} {...p} />} />
          <Route path="/ms-defender-atp" exact render={(p) => <MSIntegration name="Microsoft Defender ATP"
            app_client_id={process.env.REACT_APP_MS_ATP_ID!} {...p} />} />
          <Route path="/ms-azure-sc" exact render={(p) => <MSIntegration name="Azure Security Center"
            app_client_id={process.env.REACT_APP_AZURE_SC_ID!} {...p} />} />
          <Route path="/ms-azure-compute" exact render={(p) => <MSIntegration name="Azure Compute"
            app_client_id={process.env.REACT_APP_AZURE_COMPUTE_ID!} {...p} />} />
          <Route path="/ms-graph-files" exact render={(p) => <MSIntegration name="Microsoft Graph Files"
            app_client_id={process.env.REACT_APP_MS_GRAPH_FILES_ID!} {...p} />} />
          <Route path="/panw-auth" exact render={(p) => <CDLIntegrationCustomerValidation name="Strata Logging Service"
            app_client_id={process.env.REACT_APP_CDL_ID!} {...p} />} />
          <Route path="/panw" exact render={(p) => <CDLIntegrationShowDetails name="Strata Logging Service"
            app_client_id={process.env.REACT_APP_CDL_ID!} {...p} />} />
          <Route path="/cdl-auth" exact render={(p) => <CDLIntegrationCustomerValidation name="Strata Logging Service"
            app_client_id={process.env.REACT_APP_CDL_ID!} {...p} />} />
          <Route path="/cdl" exact render={(p) => <CDLIntegrationShowDetails name="Strata Logging Service"
            app_client_id={process.env.REACT_APP_CDL_ID!} {...p} />} />
          <Route path="/ms-management-api" exact render={(p) => <MSIntegration name="Microsoft Management API" is_non_admin={true} base_url="https://login.windows.net/"
            resource="https://manage.office.com" use_alternative_endpoint={true} app_client_id={process.env.REACT_APP_MS_MANAGEMENT_ID!} {...p} />} />
          <Route path="/ms-azure-sentinel" exact render={(p) => <MSIntegration name="Azure Sentinel" is_non_admin={true}
            resource="https://management.core.windows.net" use_alternative_endpoint={true} app_client_id={process.env.REACT_APP_AZURE_SENTINEL_ID!} {...p} />} />
          <Route path="/ms-azure-log-analytics" exact render={(p) => <MSIntegration name="Azure Log Analytics" is_non_admin={true}
            resource="https://api.loganalytics.io" use_alternative_endpoint={true} app_client_id={process.env.REACT_APP_AZURE_LOG_ANALYTICS_ID!} {...p} />} />
          <Route path="/ms-ews-o365" exact render={(p) => <MSIntegration name="EWS O365"
            app_client_id={process.env.REACT_APP_MS_EWS_O365_ID!} {...p} />} />
          <Route path="/authcode" exact render={(p) => <GenericAuthCode {...p} />} />
          <Route path="/privacy" exact render={(p) => <Container text textAlign="center">

            <br />
            <Segment inverted size="large">
              Review our privacy policy at: <a href="https://www.paloaltonetworks.com/legal-notices/privacy">https://www.paloaltonetworks.com/legal-notices/privacy</a>
            </Segment>
          </Container>}
          />
        </Grid.Column>
      </Grid>
    </Router>
  );
}

export default App;
