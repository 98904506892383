import React, {Dispatch, SetStateAction, useState} from "react";
import {GenericIntegrationProps, IntegrationProps} from "./Integration";
import {IntegrationMsg, IntegrationMsgProps} from "./IntegrationMsg";
import {Button, Container, Header, Table} from "semantic-ui-react";
import {verify_demisto_client} from "./CDL_verify_demisto_customer";
import {IntegrationSettings} from "./IntegrationSettings";

/**
 * Google Integration component
 */

//key to use to avoid resubmission on refresh
const FETCHED_TOKENS = "cdl_cache.tokens";
const clear_session_storage: React.MouseEventHandler<HTMLButtonElement> = e => {
    sessionStorage.removeItem(FETCHED_TOKENS);
};

const CDLOAuthEndpoint = "https://identity.paloaltonetworks.com/sp/startSSO.ping";
interface CDLIntegrationStateProps extends IntegrationMsgProps {
    token?: string;
    id?: string;
    key?: string;
    license_id?: string;
    customer_name?: string;
    is_token_verified?: boolean
}

interface CDLIntegrationProps extends IntegrationProps {
    msg_props: CDLIntegrationStateProps
    validate_client: (props: CDLIntegrationStateProps, set_state: Dispatch<SetStateAction<CDLIntegrationStateProps>>) => void
}


const CDLIntegrationCustomerValidation: React.FC<GenericIntegrationProps> = props => {
    function get_redirect_url(): string {
        // dev and prod apps have different redirect urls
        if (window.location.href.includes("cdl"))
            return "https://oproxy.demisto.ninja/cdl";
        else
            return "https://oproxy-dev.demisto.ninja/panw";
    }

    async function validate_customer(msg_props: CDLIntegrationStateProps, set_state: Dispatch<SetStateAction<CDLIntegrationStateProps>>) {
        try {
            set_state({
                msg_is_error: false,
                msg_body: `Validating ...`,
                msg_loader: true
            });
            if (!msg_props.license_id || !msg_props.customer_name) {
                set_state({
                    msg_title: "License ID or Customer Name not provided",
                    msg_body: "Please provide a valid License ID and Customer Name",
                    msg_is_error: true,
                    is_token_verified: false
                });
            } else {
                let client_verified = await verify_demisto_client(msg_props.license_id, msg_props.customer_name);
                client_verified = JSON.parse(client_verified).result;
                console.log("Client verified: " + client_verified);
                set_state({
                    msg_title: !!client_verified ? null : "Validation failed",
                    msg_body: !!client_verified ? null : "Failed verifying Customer Name and License ID",
                    msg_is_error: !client_verified,
                    is_token_verified: !!client_verified
                });
                if (client_verified) {
                    window.location.href = generate_consent_url(msg_props.customer_name, msg_props.license_id);
                }
            }
        } catch (error) {
            console.log(error.message);
            set_state({
                msg_is_error: true,
                msg_title: "Could not verify Demisto Customer",
                msg_body: `${error}`
            })
        }
    }

    function generate_consent_url(customer_name: string, license_id: string) {
        const redirect = get_redirect_url();
        const url_params = new URLSearchParams(props.location.search);
        let params = url_params.get("params") as string;
        const decodedParams = window.atob(params);
        const splitParams = decodedParams.split('&');
        let instanceID = '';
        let region = '';
        splitParams.forEach(function (k) {
            if (k.includes('instance_id')) {
                const splitKey = k.split('=');
                instanceID = splitKey[1];
            }
            if (k.includes('region')) {
                const splitKey = k.split('=');
                region = splitKey[1];
            }
        });
        const identityServerParams = {
            'response_type': 'code',
            'client_id': props.app_client_id,
            'redirect_uri': redirect,
            'scope': encodeURIComponent('logging-service:read'),
            'instance_id': instanceID,
            'state': [region, instanceID, customer_name, license_id].join(":"),
        };
        let query = "?PartnerIdpId=http%3A%2F%2Fwww.okta.com%2Fexk9mx8kpFWP2GaR40j6&TargetResource=https%3A%2F%2Fidentity.paloaltonetworks.com%2Fas%2Fauthorization.oauth2%3F";
        Object.entries(identityServerParams).forEach(
            ([key, value]) => query = query + encodeURIComponent("&" + key + "=" + value));
        return CDLOAuthEndpoint + query;
    }
    
    const url_params = new URLSearchParams(props.location.search);
    let params = url_params.get("params") as string;
    const [state_props, set_state_props] = useState<CDLIntegrationStateProps>({
        msg_body: !!params ? null : "Error: no params",
        msg_title: !!params ? null : "no params",
        msg_is_error: !!params,
        is_token_verified: false
    });

    const CDLIntegrationBase: React.FC<CDLIntegrationProps> = (props) => {
        return (
            <Container text textAlign="center">
                <br/>
                {!props.msg_props.is_token_verified &&
                <Header as="h1" inverted>
                    Configure: Strata Logging Service
                </Header>}
                <Header as="h3" inverted>
                    See integration documentation for details
                </Header>
                <IntegrationMsg {...props.msg_props} />
                <br/>
                <Table inverted>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell className="ui inverted header" textAlign={"center"} width={4}>
                                XSOAR License ID:
                            </Table.Cell>
                            <Table.Cell verticalAlign={"middle"} width={7}>
                                <div className="ui fluid icon input">
                                    <input type="text"
                                           onChange={event => props.msg_props.license_id = event.target.value}/>
                                </div>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>

                < Table inverted>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell className="ui inverted header" textAlign={"center"} width={4}>
                                XSOAR Customer Name:
                            </Table.Cell>
                            <Table.Cell width={7}>
                                <div className="ui fluid icon input">

                                    <input type="text"
                                           onChange={event => props.msg_props.customer_name = event.target.value}/>
                                </div>

                            </Table.Cell>

                        </Table.Row>
                    </Table.Body>

                </Table>
                <br/>

                {!props.msg_props.is_token_verified &&
                <Button as="a" inverted color="green" size="big"
                        onClick={(e) => props.validate_client(state_props, set_state_props)}>Start
                    Authorization Process </Button>
                }

                <IntegrationSettings {...props.settings_props} />


            </Container>
        )
    };

    return (
        <CDLIntegrationBase
            name="Strata Logging Service"
            show_auth_button={!!params}
            msg_props={state_props}
            settings_props={{entries: []}}
            auth_on_click={clear_session_storage}
            validate_client={validate_customer}
        />
    );
};

export default CDLIntegrationCustomerValidation;

