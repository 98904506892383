import React from 'react';
import { Button, Container, Header } from 'semantic-ui-react';
import { IntegrationMsg, IntegrationMsgProps } from './IntegrationMsg';
import { IntegrationSettings, IntegrationSettingsProps } from './IntegrationSettings';
import { RouteComponentProps } from 'react-router-dom';

// Generic Props interface to be used by Integrations (or extended)
export interface GenericIntegrationProps extends RouteComponentProps {
    name: string,
    app_client_id: string,
    is_non_admin?: boolean,
    resource?: string,
    use_alternative_endpoint?: boolean,
    base_url?: string,
}

export interface IntegrationProps{
    name: string,
    show_auth_button: boolean,
    auth_url?: string,
    auth_on_click?: React.MouseEventHandler<HTMLButtonElement>
    msg_props: IntegrationMsgProps,
    settings_props: IntegrationSettingsProps,
}

export const Integration: React.FC<IntegrationProps> = (props) => {
    return (
        <Container text textAlign="center">
        <br />
        <Header as="h1" inverted>
            Configure: {props.name}
        </Header>
        <IntegrationMsg {...props.msg_props} />
        <br />
        <IntegrationSettings {...props.settings_props} />
        {props.show_auth_button &&
            <Button as="a" inverted color="green" size="big" href={props.auth_url} onClick={props.auth_on_click}>Start Authorization Process</Button>
        }
        </Container>
    )
};
