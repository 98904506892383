import React, {useState} from 'react';
import { Icon, Table, Input, Popup, Button } from 'semantic-ui-react';
import copy_to_clipboard from 'copy-to-clipboard';

export interface SettingsEntry {
    name: string,
    value: string,
}

export interface IntegrationSettingsProps {
    entries: SettingsEntry[]
}

export const IntegrationSettingsRow: React.FC<SettingsEntry> = ({ name, value }) => {
    const COPY_MSG = "Copy to clipboard."
    const [popup_msg, set_popup_msg] = useState(COPY_MSG);
    const on_copy: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        copy_to_clipboard(value);
        set_popup_msg("Copied Successfully!")
        setTimeout(() => {
            set_popup_msg(COPY_MSG);
        }, 2000);
    }
    const res = (
        <Table.Row>
            <Table.Cell width={1}>{name}</Table.Cell>
            <Table.Cell width={7}>
                <Input readOnly fluid
                    action={
                        <Popup position="top left"
                            trigger={<Button icon='copy' onClick={on_copy} />}
                            content={popup_msg}
                            on="hover"
                        />
                    }
                    defaultValue={value}
                />
            </Table.Cell>
        </Table.Row>
    );
    return res;
};


export const IntegrationSettings: React.FC<IntegrationSettingsProps> = ({entries}) => {    
    if (entries.length > 0) {
        const settings_rows = entries.map((entry) => <IntegrationSettingsRow key={entry.name} name={entry.name} value={entry.value} />);
        return (
            <Table definition inverted>
                <Table.Header>
                    <Table.Row>
                        <Table.Cell colSpan={2} textAlign="left"><Icon name="check" />Success! Please copy to your Demisto instance integration configuration:</Table.Cell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {settings_rows}
                </Table.Body>
            </Table>
        );
    }
    return null;
};