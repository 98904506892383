import * as Msal from 'msal';
import React, { useEffect, useState } from 'react';
import { GenericIntegrationProps, Integration } from './Integration';
import { IntegrationMsgProps } from './IntegrationMsg';
import { SettingsEntry } from './IntegrationSettings';
import { register_ms_consent } from './register-api';
import { List } from 'semantic-ui-react'

/**
 * Microsoft Integration component
 */

//key to use to indicate that we are doing a redirect. once set we will not try to redirect again to avoid an endless loop
const REDIRECT_LOGIN_INDICATOR = "integration.login.redirect";
const REDIRECT_AUTH_INDICATOR = "integration.auth.redirect";
const REDIRECT_ERROR = "integration.redirect.error";

const scopes = ["user.read"];

const login_request: Msal.AuthenticationParameters = {
  scopes,
};

const token_request: Msal.AuthenticationParameters = {
  scopes,
  prompt: "consent",
};

const clear_redirect_indication: React.MouseEventHandler<HTMLButtonElement> = (e) => {
  sessionStorage.removeItem(REDIRECT_AUTH_INDICATOR);
  sessionStorage.removeItem(REDIRECT_LOGIN_INDICATOR);
  sessionStorage.removeItem(REDIRECT_ERROR);
}

interface MSIntegrationStateProps extends IntegrationMsgProps {
  reg_id?: string,
  reg_token?: string,
  reg_key?: string,
}

const MSIntegration: React.FC<GenericIntegrationProps> = (props) => {
  // redirect url should be only up to the path name ignoring params
  // const is_admin_flow = props.location.pathname !== "/ms-graph-mail-listener" ? true : false;
  const redirect = window.location.origin + props.location.pathname;
  const base_url = props.base_url ? props.base_url : `https://login.microsoftonline.com/`;
  // Azure Sentinel, Azure Log Analytics and Microsoft Management API use an endpoint and query arguments different from the standard ones.
  // It also uses non admin consent, thus the changes are only relevant in the non_admin_consent case.
  const non_admin_consent_endpoint = props.use_alternative_endpoint ? `common/oauth2/authorize?` : `common/oauth2/v2.0/authorize?`;
  console.log('alternative endpoint: ', props.use_alternative_endpoint)
  const non_admin_consent_url_params = props.use_alternative_endpoint
      ? `response_type=code&resource=${props.resource}&client_id=${props.app_client_id}&redirect_uri=${redirect}`
      : `client_id=${props.app_client_id}&response_type=code&redirect_uri=${redirect}&scope=offline_access%20mail.readwrite%20mail.send%20user.read%20profile%20openid%20email`
  const consent_url = props.is_non_admin
      ? base_url + non_admin_consent_endpoint + non_admin_consent_url_params
      : base_url + `common/adminconsent?client_id=${props.app_client_id}&redirect_uri=${redirect}`;
  const params = new URLSearchParams(props.location.search);
  const code = params.get("code"); //is used in non admin consent flow
  const consent = props.is_non_admin? code : params.get("admin_consent") === "True";
  const tenant = params.get('tenant');
  const show_auth_button = !(consent && !params.get('error')) && !params.get('response_type');
  const [state_props, set_state_props] = useState<MSIntegrationStateProps>({
    msg_body: consent ? params.get('error_description') : null,
    msg_title: consent ? params.get('error') : null,
    msg_is_error: !consent,
  });

  const redirect_error = sessionStorage.getItem(REDIRECT_ERROR);
  console.log("redirect_error: ", redirect_error, state_props.msg_is_error);
  if (redirect_error && !state_props.msg_is_error) {
    try {
      const err_msg = JSON.parse(redirect_error);
      set_state_props(err_msg);
    } catch (error) {
      console.error("Failed JSON.parse for: %s", redirect_error, error);
    }
  }

  const redirect_callback = async (error: Msal.AuthError, response?: Msal.AuthResponse) => {
    if (error) {
      console.log('redirect error: [%s] [%s]', error.name, error.message);
      const err_msg = {
        msg_title: "User Authentication Failed",
        msg_body: "Please make sure you are logged in with your Microsoft account.",
        msg_is_error: true,
      };
      set_state_props(err_msg);
      sessionStorage.setItem(REDIRECT_ERROR, JSON.stringify(err_msg));
    } else {
      if (response && response.tokenType === "access_token") {
        console.log('Got access token. size: ', response.accessToken.length)
        register_consent(response.accessToken);
      } else {
        console.log("got non access token response: ", response);
      }
    }
  }

  const register_consent = async (token: string) => {
    try {

      if (state_props.reg_id && state_props.reg_token) {
        console.log("consent is already registered", state_props);
        return;
      }
      set_state_props({
        msg_is_error: false,
        msg_body: `Obtaining token and key ...`,
        msg_loader: true,
      });
      let path = props.location.pathname;
      if (path.startsWith("/")) {
        path = path.slice(1);
      }
      const res = props.is_non_admin ? await register_ms_consent(token, code!, path, props.is_non_admin) : await register_ms_consent(token, tenant!, path);
      set_state_props({
        reg_id: res.id,
        reg_token: res.token,
        reg_key: res.key
      });
    } catch (error) {
      let msg_body = undefined;
      if (error.response && error.response.data) {
        msg_body = `${error.response.data}`;
      }
      set_state_props({
        msg_is_error: true,
        msg_title: `${error}`,
        msg_body,
      });
    }
  }
  const [msal_instance] = useState<Msal.UserAgentApplication>(() => {
    const msalNew = new Msal.UserAgentApplication({
      auth: {
        clientId: props.app_client_id,
      }
    });
    msalNew.handleRedirectCallback(redirect_callback);
    return msalNew;
  });
  const obtain_user_token = async () => {
    let token: string | undefined;
    if (msal_instance.isCallback(window.location.hash)) {
      return;
    }
    if (!msal_instance.getAccount()) {
      if (!sessionStorage.getItem(REDIRECT_LOGIN_INDICATOR)) {
        set_state_props({
          msg_is_error: false,
          msg_body: `Verifying User...`,
          msg_loader: true,
        });
        console.log('Obtaining login redirect');
        sessionStorage.setItem(REDIRECT_LOGIN_INDICATOR, `${Date()}`)
        msal_instance.loginRedirect(login_request);
      }
      return;
    }
    try {
      const resp = props.is_non_admin
      ? await msal_instance.acquireTokenSilent(login_request)
      : await msal_instance.acquireTokenSilent(token_request);
      token = resp.accessToken;
      console.log("acquireTokenSilent success. Token size: ", token.length);
      register_consent(token);
    } catch (error) {
      console.log("Failed silent request: ", error);
      if (sessionStorage.getItem(REDIRECT_LOGIN_INDICATOR)) {
        // probably don't have third party cookies
        let msg_body: string | JSX.Element = `${error}`;
        let msg_title = `Error obtaining login token`;
        const disabled_cookies_msgs = [
          'AADSTS50058', 
          'InteractionRequiredAuthError',
        ];
        if (error.errorMessage && (disabled_cookies_msgs.some(err_msg => error.errorMessage.startsWith(err_msg)))) {
          msg_body = (<span> {`Session has expired or you don't have third-party cookies enabled. Recommended actions:`}
            <List bulleted>
              <List.Item>{'Use chrome browser'}</List.Item>
              <List.Item>{'Make sure third-party cookies are enabled'}</List.Item>
              <List.Item>{'Close the browser and restart the session'}</List.Item>
            </List>
            <br></br>
            {`Full error message: ${msg_body}`}
          </span>
          );
          msg_title += ' (make sure third-party cookies are enabled)';
        }
        set_state_props({
          msg_is_error: true,
          msg_title,
          msg_body,
        });
      }
    }
    if (!token) {
      if (!sessionStorage.getItem(REDIRECT_LOGIN_INDICATOR)) {
        set_state_props({
          msg_is_error: false,
          msg_body: `Verifying User...`,
          msg_loader: true,
        });
        console.log("acquire token redirect")
        sessionStorage.setItem(REDIRECT_LOGIN_INDICATOR, `${Date()}`)

        if (props.is_non_admin) {
          msal_instance.acquireTokenRedirect(login_request);
        } else {
          msal_instance.acquireTokenRedirect(token_request);
        }
      }
      return;
    }
  }

  useEffect(() => {
    if (consent && !state_props.msg_body && !state_props.msg_title && !state_props.reg_id && !state_props.reg_token) {
      //consent provided let's get a user auth token
      console.log("calling obtain user token");
      obtain_user_token();
    }
    // Ignore the dependency warning as we want to obtain the user token only once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const settings_entries: SettingsEntry[] = [];
  if (state_props.reg_key) {
    settings_entries.push({ name: "ID", value: state_props.reg_id! })
    settings_entries.push({ name: "Token", value: state_props.reg_token! })
    settings_entries.push({ name: "Key", value: state_props.reg_key! })
  }

  return (
    <Integration name={props.name}
      show_auth_button={show_auth_button}
      msg_props={state_props}
      settings_props={{ entries: settings_entries }}
      auth_url={consent_url}
      auth_on_click={clear_redirect_indication}
    />
  )
}

export default MSIntegration;
