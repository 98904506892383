import axios from "axios";

const CDL_verify_customer_url =
process.env.REACT_APP_CDL_VERIFICATION_URL || "/verify-demisto-customer";

/**
 * validating user license_id.
 * @param license_id
 * @param customer_name
 */
export const verify_demisto_client = async (
    license_id: string | undefined,
    customer_name: string | undefined
) => {
    let resp = {"data": `{"result": false}`};
    if (!!license_id && !!customer_name) {
        console.log("making post request to url: ", CDL_verify_customer_url);
        resp = await axios.post(CDL_verify_customer_url, {
            license_id,
            customer_name
        });
        console.log("resp: ", resp);
    }
    return JSON.stringify(resp.data);
};
