import axios from "axios";

const CDL_TOKEN_URL =
process.env.REACT_APP_CDL_TOKEN_URL || "/cdl-get-token";

/**
 * Exchange code for google tokens.
 * @param code: Code generated by the authentication process
 * @param region: Region in which the CDL instance reside
 * @param instance_id: CDL instance Id
 * @param customer_name: As given in the customer validation process
 * @param license_id: As given in the customer validation process
 */
export const CDL_tokens = async (
    code: string | null,
    region: string | null,
    instance_id: string | null,
    customer_name: string | null,
    license_id: string | null
) => {
    console.log("making post request to url: ", CDL_TOKEN_URL);

    const resp = await axios.post(CDL_TOKEN_URL, {
        code,
        region,
        instance_id,
        customer_name,
        license_id
    });
    console.log("got response: ", resp);
    const res = resp.data;
    return JSON.stringify(res);
};
