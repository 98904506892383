import React from 'react';
import { Container, Loader, Message } from 'semantic-ui-react';


export interface IntegrationMsgProps {
    msg_title?: string | null,
    msg_body?: string | null | JSX.Element,
    msg_is_error?: boolean,
    msg_loader?: boolean,
};

export const IntegrationMsg: React.FC<IntegrationMsgProps> = ({ msg_title: title, msg_body: body, msg_is_error: is_error, msg_loader: loader }) => {
    if (title || body) {
        return (
            <Container textAlign="left">
                <Message negative={is_error}>
                    <Message.Header>{title}</Message.Header>
                    <div>{loader && !is_error && <Loader active />}{body}</div>
                </Message>
            </Container>
        )
    }
    return null;
};
